<template>
  <!-- begin:: Footer -->
  <div
    class="footer bg-white py-4 d-flex flex-lg-column"
    id="kt_footer"
    style="background-color:#E1DEE6 !important"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <!-- <span class="text-muted font-weight-bold mr-2">
          2020 &nbsp;&copy;&nbsp;
        </span> -->
        <!-- <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          Keenthemes
        </a> -->
      </div>
      <div class="nav nav-dark">
        <v-row>
          <div class="mr-5">
            <a
              href="http://ner-consulting.com/"
              target="_blank"
              class="nav-link "
            >
              Hakkımızda
            </a>
            <p>www.ner-consulting.com</p>
          </div>
          <!-- <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="nav-link px-3"
        >
          Team
        </a> -->
          <div class="mr-5">
            <a
              href="http://ner-consulting.com/"
              target="_blank"
              class="nav-link "
            >
              Bize Ulaşın
            </a>
            <p class="m-auto">info@ner-consulting.com</p>
          </div>
        </v-row>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
