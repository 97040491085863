<template>
  <v-navigation-drawer permanent style="background-color:#442941;">
    <v-list dense>
      <v-list-item-group>
        <!--eslint-disable-->
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          color="white"
          style="height: 60px"
          link
          :to="item.to"
          v-if="item.isShow"
        >
          <v-list-item-icon
            style="margin-top: auto; margin-bottom: auto; margin-right:10px"
            class="abc"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <!-- <v-list-item-title style="font-size: 16px;color:white;margin:auto;">{{
            $t(item.label)
          }}</v-list-item-title> -->
          <h5 class="menuItem">{{ $t(item.label) }}</h5>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  data() {
    return {
      globalAdmin: false,
      cro: false,
      user: false,
      items: []
    };
  },
  methods: {},
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
      "currentUser",
      "isGlobalAdmin",
      "isCro",
      "isNormalUser"
    ])
  },
  mounted() {
    this.globalAdmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    this.items = [
      {
        title: "Çalışmalar",
        label: "Çalışma Listesi",
        to: "/workslist",
        icon: "menu-icon flaticon-list text-warning medium",
        isShow: this.globalAdmin || this.cro || this.user
      },
      {
        label: "Çalışma Ekle",
        to: "/works",
        icon: "flaticon-plus text-warning",
        isShow: this.globalAdmin || this.cro
      },
      {
        title: "Kullanıcılar",
        label: "Kullanıcılar Listesi",
        to: "/userlist",
        icon: "flaticon-users text-warning",
        isShow: this.globalAdmin || this.cro
      },
      {
        label: "Kullanıcı Ekle",
        to: "/users",
        icon: "flaticon-plus text-warning",
        isShow: this.globalAdmin || this.cro
      },
      {
        title: "Merkezler",
        label: "Merkez Listesi",
        to: "/centerlist",
        icon: "flaticon-location text-warning",
        isShow: this.globalAdmin || this.cro || this.user
      },
      {
        label: "Merkez Ekle",
        to: "/centers",
        icon: "flaticon-plus text-warning",
        isShow: this.globalAdmin
      },
      {
        title: "Organizasyonlar",
        label: "Organizasyon Listesi",
        to: "/organizationlist",
        icon: "flaticon-network text-warning",
        isShow: this.isGlobalAdmin || this.cro || this.user
      },
      {
        label: "Organizasyon Ekle",
        to: "/organizations",
        icon: "flaticon-plus text-warning",
        isShow: this.isGlobalAdmin
      },
      {
        label: "KVKK",
        to: "/kvkk",
        icon: "menu-icon flaticon-list text-warning medium",
        isShow: this.isGlobalAdmin
      }
    ];
  }
};
</script>
